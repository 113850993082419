import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, useMediaQuery } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerStore } from '../store/CustomerJourneyStore';
import BenefitsV2 from './journey/BenefitsV2';
import ConsumptionPattern from './journey/ConsumptionPattern';
import ContactV2 from './journey/ContactV2';
import ElectricityBillV2 from './journey/ElectricityBillV2';
import FullName from './journey/FullName';
import InstallationTimelineV2 from './journey/InstallationTimelineV2';
import PlantSizeOptions from './journey/PlantSizeOptions';
import PropertyType from './journey/PropertyType';
import SanctionLoadV2 from './journey/SanctionLoadV2';
import SolarRoofFinderV2 from './journey/SolarRoofFinderV2';

export default function JourneyStepper() {
  const theme = useTheme();
  const [toggle] = React.useState('flex');
  const {
    activeStep,
    setActiveStep,
    setSubmitIsClicked,
    finalFlag,
    isSaveDetailsButtonDisabled,
    setIsSaveDetailsButtonDisabled,
  } = useCustomerStore();
  const { t } = useTranslation();
  const maxSteps = 10;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const steps = [
    SolarRoofFinderV2,
    PropertyType,
    ElectricityBillV2,
    ConsumptionPattern,
    SanctionLoadV2,
    BenefitsV2,
    PlantSizeOptions,
    InstallationTimelineV2,
    ContactV2,
    //Register, (Do not uncomment or remove)
    FullName,
  ];

  const handleNext = () => {
    setSubmitIsClicked(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (isSaveDetailsButtonDisabled) setIsSaveDetailsButtonDisabled(false);
  };

  React.useEffect(() => {
    if (finalFlag && activeStep === maxSteps - 1) {
      handleNext();
    }
  }, [finalFlag]);

  const CurrentStepComponent = activeStep < maxSteps ? steps[activeStep] : null;

  return (
    <>
      <Box>
        <Box
          sx={{
            height: { sm: '100%', xs: '100vh' },
          }}
        >
          {CurrentStepComponent ? <CurrentStepComponent /> : null}
        </Box>
        <Box
          position='absolute'
          top={0}
          right={0}
          bottom={0}
          left={0}
          sx={{
            display: toggle === 'flex' ? 'none' : 'grid',
            placeContent: 'center',
          }}
        >
          <Box width={{ md: 440, xs: 240 }} id='tick-logo' />
        </Box>

        {activeStep === maxSteps - 1 && finalFlag ? (
          ''
        ) : (
          <MobileStepper
            variant='progress'
            steps={maxSteps}
            position={isMobile ? 'bottom' : 'static'}
            activeStep={activeStep}
            sx={{
              py: '28px',
            }}
            nextButton={
              <Button
                variant='contained'
                disableElevation
                size={isMobile ? 'small' : 'large'}
                onClick={handleNext}
                //disabled={isSaveDetailsButtonDisabled}
              >
                {activeStep === maxSteps - 1
                  ? t('main.buttons.finish')
                  : t('main.buttons.next')}
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size={isMobile ? 'small' : 'large'}
                variant='text'
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                {t('main.buttons.back')}
              </Button>
            }
          />
        )}
      </Box>
    </>
  );
}
