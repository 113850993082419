import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Materials } from '../../utils/app.enums';
import ArrangeVisitDialog from '../ui/ArrangeVisitDialog';
import RateExperience from '../ui/RateExperience';

const OffersNearYou = ({ recommendations, propertyDetails, userDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [selectedRecommendationId, setSelectedRecommendationId] =
    useState(null);
  const [arrangedVisits, setArrangedVisits] = useState({});
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const openArrangeSiteVisit = (vendorId, configId, recommendationId) => {
    setSelectedVendorId(vendorId);
    setSelectedConfigId(configId);
    setSelectedRecommendationId(recommendationId);
    setIsDialogOpen(true);
  };

  const closeArrangeSiteVisit = (isVisitCreated) => {
    setIsDialogOpen(false);
    setSelectedVendorId(null);
    setSelectedConfigId(null);
    if (isVisitCreated) {
      setArrangedVisits({
        ...arrangedVisits,
        [selectedRecommendationId]: true,
      });
      setIsFeedbackOpen(true);
    }
  };

  const closeRateExperience = () => {
    setIsFeedbackOpen(false);
  };

  const openVendorProfile = (vendor) => {
    const vendorNameEncoded = vendor?.establishmentName.replace(/\s+/g, '-');
    const vendorInfo = `${vendorNameEncoded}_${vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  return (
    <>
      <Box>
        <Stack mb={6}>
          <Stack width={'100%'}>
            {recommendations.map((offer, index) => {
              const plantInfo = offer?.proposalConfig?.config?.plantInfo;
              const pricing = offer?.proposalConfig?.config?.pricing;
              const postGstCost = Math.round(
                pricing?.installationCost * (1 + pricing?.gst / 100),
              );
              const finalCostAfterSubsidy = pricing?.totalCost - 3000;
              const solarPanelObj =
                offer?.proposalConfig?.config?.materials?.find(
                  (material) => material.product === Materials.SOLAR_PANEL,
                );
              const inverterObj =
                offer?.proposalConfig?.config?.materials?.find(
                  (material) => material.product === Materials.INVERTER,
                );
              const materialsCount =
                offer?.proposalConfig?.config?.materials?.length;
              return (
                <Accordion
                  key={index}
                  sx={{
                    width: '100%',
                    boxShadow: 'none',
                    alignContent: 'flex-start',
                    paddingInline: { sm: '20px', xs: '2px' },
                    borderRadius: '9px !important',
                    backgroundColor: theme.palette.secondary.lighter,
                    '.MuiAccordionSummary-expandIconWrapper': {
                      margin: '13px 0',
                      marginLeft: '4px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMoreIcon />
                      </IconButton>
                    }
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.h6.fontSize,
                          fontWeight: 'bold',
                        }}
                      >
                        {plantInfo?.plantSize}kW{' '}
                        {plantInfo?.plantType?.toLowerCase()}
                      </Typography>
                      <br />
                      <Stack direction={'column'} flex={1}>
                        <Link
                          variant='subtitle2'
                          onClick={() => openVendorProfile(offer?.vendor)}
                        >
                          by {offer?.vendor?.establishmentName}
                          <br />
                          Serves in {offer.proposalConfig.district},{' '}
                          {offer.proposalConfig.state}
                        </Link>
                      </Stack>
                    </Box>
                    <Stack
                      direction={isMobile ? 'column' : 'row-reverse'}
                      alignItems={'center'}
                      spacing={2}
                      sx={{ flexGrow: 1 }}
                    >
                      <Button
                        size={isMobile ? 'small' : 'medium'}
                        variant='contained'
                        onClick={(event) => {
                          event.stopPropagation();
                          openArrangeSiteVisit(
                            offer?.vendor?.id,
                            offer?.proposalConfig?.id,
                            offer?.id,
                          );
                        }}
                        disabled={
                          offer?.isConverted || arrangedVisits[offer?.id]
                        }
                      >
                        {offer?.isConverted || arrangedVisits[offer?.id]
                          ? 'Visit arranged'
                          : 'Arrange visit'}
                      </Button>
                      <Typography
                        sx={{
                          color: 'green',
                          fontWeight: 'bold',
                          fontSize: isMobile
                            ? theme.typography.body1.fontSize
                            : theme.typography.h6.fontSize,
                        }}
                      >
                        ₹ {finalCostAfterSubsidy?.toLocaleString('en-IN')}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container mt={2} alignItems={'start'}>
                      <Grid item xs={6}>
                        <Typography
                          fontWeight='bold'
                          sx={{ fontSize: theme.typography.h6.fontSize }}
                        >
                          Solar panels <br />
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: theme.typography.body1.fontSize,
                            mt: 0,
                          }}
                        >
                          {solarPanelObj?.brand} - {solarPanelObj?.description}{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          fontWeight='bold'
                          sx={{ fontSize: theme.typography.h6.fontSize }}
                        >
                          x{solarPanelObj?.quantity}
                          <br />
                        </Typography>
                        <Typography
                          variant='caption'
                          sx={{
                            fontSize: theme.typography.body1.fontSize,
                            mt: 0,
                          }}
                        >
                          {solarPanelObj?.warrantyInYears} years warranty
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={3} alignItems={'start'}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.h6.fontSize,
                          }}
                        >
                          Inverter <br />
                        </Typography>
                        <Typography
                          variant='caption'
                          sx={{ fontSize: theme.typography.body1.fontSize }}
                        >
                          {inverterObj?.brand} {inverterObj?.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.h6.fontSize,
                          }}
                        >
                          x{inverterObj?.quantity} <br />
                        </Typography>
                        <Typography
                          sx={{ fontSize: theme.typography.body1.fontSize }}
                        >
                          {inverterObj?.warrantyInYears} years warranty
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        mt={1.5}
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'underline',
                        }}
                      >
                        <Typography variant='subtitle2'>
                          {materialsCount - 2 > 0
                            ? materialsCount - 2 === 1
                              ? `& ${materialsCount - 2} other component`
                              : `& ${materialsCount - 2} other components`
                            : ''}
                          <br />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ margin: '20px 0px' }} />
                    <Grid container mt={3}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          Payable <br />
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          (-) Subsidy
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          ₹ {postGstCost?.toLocaleString('en-IN')}
                          <br />
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          - ₹{' '}
                          {(
                            pricing?.applicableStateSubsidy +
                            pricing?.applicableCentralSubsidy
                          ).toLocaleString('en-IN')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={1.5} mb={2}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          Net cost
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: theme.typography.body2.fontSize,
                          }}
                        >
                          (-) VaySolar cashback
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          ₹ {pricing?.totalCost?.toLocaleString('en-IN')}
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          - ₹ 3,000
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container mt={1.5}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                          }}
                        >
                          Final cost
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                          }}
                        >
                          ₹ {finalCostAfterSubsidy?.toLocaleString('en-IN')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Stack
                      display='flex'
                      flexDirection='row-reverse'
                      justifyContent='space-between'
                      pt={5}
                      pb={1.5}
                    >
                      <Button
                        variant='contained'
                        onClick={() =>
                          openArrangeSiteVisit(
                            offer?.vendor?.id,
                            offer?.proposalConfig?.id,
                            offer?.id,
                          )
                        }
                        disabled={
                          offer?.isConverted || arrangedVisits[offer?.id]
                        }
                      >
                        {offer?.isConverted || arrangedVisits[offer?.id]
                          ? 'Visit arranged'
                          : 'Arrange visit'}
                      </Button>
                      {/* <Button variant='contained'>Quote details</Button> */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Stack>
      </Box>
      <ArrangeVisitDialog
        open={isDialogOpen}
        handleClose={closeArrangeSiteVisit}
        propertyDetails={propertyDetails}
        vendorId={selectedVendorId}
        userDetails={userDetails}
        configId={selectedConfigId}
        recommendationId={selectedRecommendationId}
      />
      <RateExperience
        open={isFeedbackOpen}
        vendorId={selectedVendorId}
        vendorName={
          recommendations.find(
            (recommendation) => recommendation.id === selectedRecommendationId,
          )?.vendor?.establishmentName
        }
        recommendationId={selectedRecommendationId}
        handleClose={closeRateExperience}
      />
    </>
  );
};

export default OffersNearYou;
