import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { getUserDetails } from '../../services/UserDetailsService';
import { INTERACTION } from '../../utils/app.constants';
import { Interaction } from '../../utils/app.enums';
import { trackGoogleAnalyticsEvent } from '../../utils/utils';

export default function RateExperience({
  open,
  handleClose,
  vendorId,
  vendorName,
  recommendationId,
}) {
  const theme = useTheme();
  const [formValues, setFormValues] = useState({
    fullName: '',
    rating: '',
  });

  const handleRatingClick = (rating) => {
    setFormValues({ ...formValues, rating });
  };

  const validateForm = () => {
    const errors = {};

    if (!formValues.rating) {
      errors.rating = 'Rating is required';
    }

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const googleAnalyticsPayload = {
      rating: formValues.rating,
      source: getUserDetails().id,
      customerName: getUserDetails().name,
      target: vendorId,
      vendorName: vendorName,
      recommendationId: recommendationId,
    };
    trackGoogleAnalyticsEvent(
      INTERACTION,
      Interaction.SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL,
      {
        ...googleAnalyticsPayload,
        createdAt: new Date(),
      },
    );
    console.log('Selected Rating:', formValues.rating);
    handleClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      sx={{
        '& .MuiDialog-paper': { borderRadius: '10px' },
      }}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>
        <Typography fontWeight={'bold'} mb={'12px'}>
          Rate your experience
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} direction='row'>
          <Button
            onClick={() => handleRatingClick('Good')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              backgroundColor:
                formValues.rating === 'Good'
                  ? theme.palette.secondary.lighter
                  : 'transparent',
              border:
                formValues.rating === 'Good'
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
            }}
          >
            <span role='img' aria-label='Good' style={{ fontSize: '2rem' }}>
              😊
            </span>
            <Typography>Good</Typography>
          </Button>
          <Button
            onClick={() => handleRatingClick('Average')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              backgroundColor:
                formValues.rating === 'Average'
                  ? theme.palette.secondary.lighter
                  : 'transparent',
              border:
                formValues.rating === 'Average'
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
            }}
          >
            <span role='img' aria-label='Average' style={{ fontSize: '2rem' }}>
              😐
            </span>
            <Typography>Average</Typography>
          </Button>
          <Button
            onClick={() => handleRatingClick('Poor')}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              backgroundColor:
                formValues.rating === 'Poor'
                  ? theme.palette.secondary.lighter
                  : 'transparent',
              border:
                formValues.rating === 'Poor'
                  ? `2px solid ${theme.palette.primary.main}`
                  : 'none',
            }}
          >
            <span role='img' aria-label='Poor' style={{ fontSize: '2rem' }}>
              😞
            </span>
            <Typography>Poor</Typography>
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          sx={{ textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          type='submit'
          variant='contained'
          sx={{ textTransform: 'none' }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
